(function ($) {
  Drupal.behaviors.contentBlockSplitWidthLogoStickyV1 = {
    attach: function (context) {
      var $logoSticky = $('.js-split-width-logo-sticky', context);
      var $logoStickyMedia = $('.js-split-width-logo-sticky__column--media', $logoSticky);
      var $logoStickyLogo = $('.js-split-width-sticky__logo', $logoSticky);
      var $contentBefore = $('.js-split-width-logo-sticky__content-before-logo', $logoSticky);
      var logoStickyHeight;
      var logoStickyTop;

      if ($logoStickyLogo.length > 0) {
        $(window).on(
          'scroll resize load',
          _.throttle(function () {
            if (
              !$logoStickyLogo.hasClass('split-width-logo-sticky__logo--scrolled') &&
              $logoStickyLogo[0].getBoundingClientRect().bottom >=
                $logoStickyMedia[0].getBoundingClientRect().bottom
            ) {
              $logoStickyLogo.addClass('split-width-logo-sticky__logo--scrolled');
            } else if (
              $logoStickyLogo.hasClass('split-width-logo-sticky__logo--scrolled') &&
              $logoStickyMedia[0].getBoundingClientRect().bottom + 40 >= $(window).innerHeight()
            ) {
              $logoStickyLogo.removeClass('split-width-logo-sticky__logo--scrolled');
            }

            if (
              !$logoStickyLogo.hasClass('split-width-logo-sticky__logo--hide') &&
              $contentBefore[0].getBoundingClientRect().bottom + 10 >=
                $logoStickyLogo[0].getBoundingClientRect().top
            ) {
              logoStickyTop = $logoStickyLogo[0].getBoundingClientRect().top;
              logoStickyHeight = $logoStickyLogo.height() + 40;
              $logoStickyLogo.addClass('split-width-logo-sticky__logo--hide');
            } else if (
              $contentBefore[0].getBoundingClientRect().bottom + 10 < logoStickyTop ||
              $contentBefore[0].getBoundingClientRect().bottom + 10 <
                $(window).innerHeight() - logoStickyHeight
            ) {
              $logoStickyLogo.removeClass('split-width-logo-sticky__logo--hide');
            }
          }, 10)
        );
      }
    }
  };
})(jQuery);
